@font-face {
  font-family: heinz;
  src: url('./HeinzLabel-Regular.woff');
}

.App {
  text-align: center;
  font-family: heinz;
}
* {
  margin: 0;
  padding: 0;
}
html, body, canvas {
  height: 100%;
  width: 100%;
  background-color: #ac1026;
  text-align: center;
}
h1 {
  color: #ac1026;
  font-size: 60px;
}
canvas {
  position: fixed;
}
.video-frame {
  margin: 3rem auto;
  width: 560px;
  height: 315px;
}
.draw-ketchup-header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.head-logo {
  margin: 2rem;
  max-width: 14rem;
}
.draw-ketchup-header img,
.draw-ketchup-header h1 {
  display: inline-block;
}
.pagination-num {
  margin: .5rem;
  cursor: pointer;
}
.pagination-num:hover {
  text-decoration: underline;
}
.selected-page {
  font-weight: 800;
  text-decoration: underline;
  color: #dbd9d5;
}
.spacer {
  height: 2rem;
}
.gallery-box {
  margin: 1rem auto;
  max-width: 1200px;
}
.gallery-image{
	border: 10px solid #dbd9d5;
  box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.5) inset;
  -webkit-box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.5) inset;
  display: inline-block;
  margin: 5px;
}

@media only screen and (max-width: 600px) {
  h1 {
    color: #ac1026;
    font-size: 40px;
  }
  .head-logo {
    max-width: 10rem;
  }
  .video-frame {
    width: 420px;
  }
}

@media only screen and (max-width: 500px) {
  .video-frame {
    width: 280px;
  }
}
@media only screen and (max-width: 400px) {
  h1 {
    color: #ac1026;
    font-size: 35px;
  }
  .head-logo {
    max-width: 8rem;
    margin: 1rem;
  }
}